import { Injectable } from '@angular/core';
import { HttpService } from '../common/http.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {



  constructor(private http: HttpService) { }

  logout() {
    return this.http.post('/web/index/logout');
  }

  refreshToken(info:any) { 
    return this.http.post('/web/index/refresh',info);
  }

  getDeviceData(sn: any) {
    return this.http.get('/web/device/devTimeZoneBySn?sn=' + sn)
  }

  checkDevExistNotRe(sn: string) {
    return this.http.get('/web/device/checkDevExistNotRepeat?sn=' + sn);
  }

  getPlantList(sn: string) {
    return this.http.get('/web/plant/plantListByTZ?inverter_sn=' + sn)
  }
  movedevice(info:any){
    return this.http.post('/web/device/moveDevice', info)
  }

  bindDevice(info: any) {
    return this.http.post('/web/device/bindDevice', info)  
  }
}
