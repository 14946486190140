import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, filter, tap, timeout, retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  isDemo:boolean = false;
  baseUrl: string;
  constructor(private http: HttpClient, private snackBar: MatSnackBar, private translate: TranslateService) { 
    this.baseUrl = document.location.protocol + '//' + environment.url
  }

  get(url: string, params?: Object, flag?: boolean): Observable<any> {
    if(this.isDemo) {
      return of('');
    } else {
      let httpParams = new HttpParams();
      if (params) {
        for (const key in params) {
          if ((params[key] && params[key] !== 'undefined' || params[key] === 0)) {
            httpParams = httpParams.append(key, <string>params[key]);
          }
        }
      }
      return this.http.get(url, { 'params': httpParams, 'withCredentials': true }).pipe(
        timeout(60000),
        retry(5),
        filter(rsp => {
          // TODO: handle error code
          rsp['code'] && console.log('error request:', rsp['msg']);
          if(flag) {
            return true
          }
          if(rsp['code'] != 0) {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3 * 1000,
              data: { msg: this.translate.instant(rsp['msg'])  },
              verticalPosition: 'top'
            });
          }
          return rsp['code'] === 0;
        }),
        catchError(this.handleError<any>('do get', []))
      );
    }
  
  }

  post(url: string, body?: Object, headers?: HttpHeaders, flag?: boolean): Observable<any> {
    return this.http.post(url, body, { withCredentials: true, responseType:"json" , headers: headers}).pipe(
      timeout(180000),
      retry(5),
      filter((rsp): any => {
        // TODO: handle error code
        if(rsp['code'] != 0) {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3 * 1000,
            data: { msg: this.translate.instant(rsp['msg']) },
            verticalPosition: 'top'
          });
          if(flag) {
            return true
          }
          return false
        } else {
          return true
        }     
      }),
      catchError(this.handleError<any>('do post', []))
    );
  }

  put(url: string, body?: Object): Observable<any> {
    return this.http.put(url, body, { withCredentials: true, responseType:"json"  }).pipe(
      timeout(60000),
      retry(5),
      filter((rsp): any => {
        // TODO: handle error code
        if(rsp['code'] != 0) {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3 * 1000,
            data: { msg: this.translate.instant(rsp['msg'])  },
            verticalPosition: 'top'
          });
        } else {
          return true
        }     
      }),
      catchError(this.handleError<any>('do put', []))
    );
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, { withCredentials: true, responseType:"json"  }).pipe(
      timeout(60000),
      retry(5),
      filter((rsp): any => {
        // TODO: handle error code
        if(rsp['code'] != 0) {
          this.snackBar.openFromComponent(SnackBarComponent, {
            duration: 3 * 1000,
            data: { msg: this.translate.instant(rsp['msg'])  },
            verticalPosition: 'top'
          });
        } else {
          return true
        }     
      }),
      catchError(this.handleError<any>('do delete', []))
    );
  }
 
  do_fetch(url: string,headers?:HttpHeaders) {
    let token = localStorage.getItem("jwtauth");
    let header: any = headers ? headers : (token ? { 'Content-type': 'application/json', 'Authorization': token } : {'Content-type': 'application/json'})
    return fetch(url,{headers: header}).then(res => res.blob())
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<any> => {
      if(error.status == 0 || error.status == 401) return throwError(error);
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      console.log(`${error.status}`);
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3 * 1000,
        data: { msg: error.name == 'TimeoutError' ? this.translate.instant('REQUEST_TIMEOUT') : `${operation} failed: ` + this.translate.instant('FAILED') },
        verticalPosition: 'top'
      });

      // Let the app keep running by returning an empty result.
      return throwError(error);
    };
  }
}
