import { Injectable, Inject, Component } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable ,throwError } from 'rxjs';
import { mergeMap, map, catchError, tap ,switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Data, ActivatedRoute, Route, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../src/app/main/main.service'

// import { MainService } from './main.service';

// import * as sha1 from 'js-sha1';

// import { LoginService } from 'src/app/login/login.service';

import { Location } from '@angular/common';
import { request } from 'http';
import { error } from 'console';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class SolarInterceptor implements HttpInterceptor {
  targetUrl;
  hasDialog: boolean = false;
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    const token = localStorage.getItem("jwtauth");
    let modifiedUrl
    if(req.url.includes('web')){
       modifiedUrl = req.url.replace('web', 'dsweb');
    }

    req = req.clone({
   
      url:modifiedUrl
    });

   
    if (token && (!req.url.includes('api.openweathermap.org/data')) && (!req.url.includes('visualisations.aemo.com.au'))) {
     
     
      req = req.clone({
        headers: req.headers.set('Authorization', token),
        url:modifiedUrl
      });
       



    }

    // return next.handle(req).pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     if (error.status === 401) {
    //       // 发送刷新 token 的请求
    //       let info = {
    //         token:token
    //       }
    //       return this.refreshToken(info).pipe(
    //         switchMap((newToken: string) => {
    //           // 如果成功获取到新 token，则更新旧 token，并重新发起原始请求
    //           req = req.clone({
    //             setHeaders: {
    //               Authorization: newToken
    //             }
    //           });
    //           return next.handle(req);
    //         }),
    //         catchError((refreshError: any) => {
    //           // 如果刷新 token 失败，则登出用户
    //           this.authLogin();
    //           return throwError(refreshError);
    //         })
    //       );
    //     }
    //     return throwError(error);
    //   })
    // )

    
   

    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse && event.status === 200) {
        // console.log(event.status);
      }
    }, error => {
      // console.log(error);
      switch (error.status) {
        case 401: // not login
        // this.refreshToken()
          this.authLogin();
          // console.log(error.statusText);
          break;
        case 404:
          // this.authLogin(); //for test
          // console.log(error.statusText);
          break;
        default:
          break;
      }
    }))
  }

  constructor(public dialog: MatDialog, private activeRoute: ActivatedRoute, private location: Location, private router: Router,
    private snackBar: MatSnackBar, private translate: TranslateService, private mainSv: MainService,) {
    // console.log(this.location.path());
    this.targetUrl = this.location.path() ? this.location.path() : '/main/home';
  }

  private authLogin() {
    if (this.location.path() == '/login') return;
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3 * 1000,
      data: { msg: this.translate.instant('Timeout_Tip') },
      verticalPosition: 'top'
    });
    this.dialog.closeAll();
    this.router.navigate(['/login']);
    // this.hasDialog = true;
    // const dialogRef = this.dialog.open(LoginDialog, {
    //   width: '450px',
    //   panelClass:'dialog',
    //   data:this.targetUrl,
    //   disableClose:true
    // });

    // dialogRef.afterClosed().subscribe().add(()=>{
    //   this.hasDialog = false;
    // })
  }
  
  refreshToken(info:any){
     return this.mainSv.refreshToken(info)
  

}
}



// @Component({
//   selector: 'login-dialog',
//   templateUrl: 'login-dialog.html',
//   styleUrls:['../../src/app/login/login.component.css']
// })
// export class LoginDialog {
//   loginForm: FormGroup;

//   email;
//   password;
//   loadFailed:Boolean = false;

//   snShow:Boolean = false;
//   constructor(public dialogRef: MatDialogRef<LoginDialog>,@Inject(MAT_DIALOG_DATA) public data: Data,private loginService:LoginService,private router:Router) {
//     this.email = window.localStorage.getItem('username');
//     this.password = window.localStorage.getItem('Remember') =='true'? window.localStorage.getItem('password') :'';
//     this.loginForm = new FormGroup({
//       email: new FormControl(this.email,[Validators.required]), 
//       password: new FormControl(this.password,[Validators.required,Validators.minLength(4),Validators.maxLength(20)]),
//     });
//   }

//   login(){
//       if (this.loginForm.value.email.indexOf('@') > -1) {
//           this.loginService.getLoginKey().subscribe(res=>{
//               if(res.code !=0 || !res.data) return;
//               var password = sha1(this.loginForm.value.password);
//               let loginInfo ={
//                   username:this.loginForm.value.email,
//                   password:sha1.create().update(res.data.key).update(password).hex()
//               }

//               this.loginService.login(loginInfo).subscribe(res=>{
//                   if(res.code == 0){
//                       this.dialogRef.close();
//                       window.location.reload();
//                       // this.router.navigate([this.data]); 
//                   }else{
//                       this.loadFailed =true;
//                   }
//               },err=>{
//                   this.loadFailed =true;
//               })

//           })
//       } else {
//           this.loginService.getDeviceLogin(this.loginForm.value.email, this.loginForm.value.password).subscribe(res=>{
//               if (res.code == 0) {
//                   this.dialogRef.close();
//                   window.location.reload();
//               } else {
//                   this.loadFailed = true
//               }
//           })
//       }
//   }

//   confirm() {
//     this.router.navigate(['/login']); 
//     this.dialogRef.close();
//   }

//   ngOnInit() {

//     if(window.localStorage.getItem('deviceArr')){
//       this.snShow = true;
//     }
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }



