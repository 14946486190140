import { Injectable } from '@angular/core';
import { HttpService } from '../common/http.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StationListService {

  constructor(private http: HttpService) { }

  getAllPlantList(name: string, sort: number) {
    let day = moment().toISOString();
    let str = '/web/home/homePlantLists?name=' + name + '&sort=' + sort + '&day=' + day;
    return this.http.get(str);
  }

  getPlantList(name: string, sort: number, page: number, limit: number, status?: any) {
    let day = moment().toISOString();
    let str = '/web/home/homePlantLists/' + page + '/' + limit + '?name=' + name + '&sort=' + sort + '&day=' + day;
    if (status || status == 0) {
      str = str + '&status=' + status;
    }
    return this.http.get(str);
  }

  getPlantDashboard() {
    let day = moment().toISOString();
    return this.http.get('/web/home/homePlantDashboard?day=' + day);
  }

  getHomePlantElectricity(type: string, beginTime?: any, endTime?: any) {
    return this.http.get('/web/home/homePlantElectricity?table=' + type + '&beginTime=' + beginTime + '&endTime=' + endTime);
  }

  getHomePlantPowerCurve(beginTime: any, endTime: any) {
    return this.http.get('/web/home/homePlantPowerCurve?beginTime=' + beginTime + '&endTime=' + endTime);
  }

  homePlantGC() {
    return this.http.get('/web/home/homePlantGC');
  }

  alarmNum() {
    return this.http.post('/web/alarm/plantCurAlarmCounts', { plant: [] });
  }

  deviceStatusByAccount() {
    return this.http.get('/web/home/deviceStatusByAccount');
  }
  getData(account:any,role:any) {
    return this.http.get('/web/bigscreen/getData?account=' + account + '&role=' + role);
  }

  getMapByIP() {
    return this.http.get('/web/bigscreen/getMapTypeByIP');
  }
}
