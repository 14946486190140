import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent implements OnInit {

  msg: any
  constructor(public snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data: string) {
    this.msg = data['msg'];
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.snackBar.dismiss()
  }
}
