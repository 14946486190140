import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { slideInAnimation } from './common/animations';
import { RouterOutlet } from '@angular/router';
import { StationListService } from './station-list/station-list.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent {
    constructor(public translate: TranslateService, private _adapter: DateAdapter<any>, private stationListSv: StationListService) {
      let langue = window.localStorage.getItem('lang');
      if(!langue) {
        langue = 'zh-cn';
        window.localStorage.setItem('lang', langue)
      }
      translate.addLangs(['en-us', 'zh-cn'])
      translate.setDefaultLang(langue);
      translate.use(langue);
      this._adapter.setLocale(langue);
      this.stationListSv.getMapByIP().subscribe((res: any) => {
        if (res.data != 'china') {
          let script = document.createElement('script'); 
          script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDowsYe8zb00qe1voH_1Z7elXfKco-c6yI"
          script.async = true
          document.getElementsByTagName('head')[0].appendChild(script); 
        }
      })
    }

    prepareRoute(outlet: RouterOutlet) {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }


}
